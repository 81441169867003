import { Box, Button, MenuItem, Typography, useTheme } from "@mui/material";
import {
    DataGrid,
    GridActionsCellItem,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridToolbarFilterButton,
    gridColumnFieldsSelector,
    gridFilteredSortedRowIdsSelector,
    useGridApiContext,
} from "@mui/x-data-grid";
import { DateTime } from "luxon";
import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    calculateCycleTime,
    getDisplayWeight,
    isGroupSterilisOrDist,
    prettyMins,
} from "src/components/library/helpers";
import { fetchCycleEvents, setSelectedCycle } from "../deviceDashboardSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { setConfigId, setFromDate } from "src/features/CycleMetric/metricSlice";
import { cycleReport } from "src/components/dashboard/CycleReport";

const CycleReportTable = () => {
    const dispatch = useDispatch();
    const {
        deviceCycles,
        deviceFacilityAddress,
        deviceSerial,
        weightUnit,
        selectedCycle,
        selectedDevice,
        biochallengeInfo,
        currentPressureUnit,
        complianceReport,
        deviceTimezone,
        userTimezone,
    } = useSelector(
        // @ts-ignore
        (store) => store.devicedashboard
    );
    // @ts-ignore
    const loading = useSelector((store) => store.devicedashboard.loading.cycleReport);
    // @ts-ignore
    const { group } = useSelector((store) => store.user);
    const { t } = useTranslation("translations");
    const [pageSize, setPageSize] = useState(25);
    const [selectionModel, setSelectionModel] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (selectedCycle && deviceCycles) {
            setSelectionModel(selectedCycle?.cycle_id);
        }
    }, [deviceCycles]);

    useEffect(() => {
        dispatch(setSelectedCycle(null));
    }, [selectedDevice]);

    const theme = useTheme();

    const handleMetricsClick = React.useCallback(
        (cycle) => () => {
            history.push(`/cycle-metrics/${cycle.metadata}`);
            dispatch(setConfigId(cycle.device_config_id));
            dispatch(setFromDate(cycle.time_started));
        },
        []
    );

    const getPdf = (apiRef) => {
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const columnFields = gridColumnFieldsSelector(apiRef);

        const cycles = filteredSortedRowIds.map((id) => {
            const row = {};
            if (complianceReport) {
                if (
                    !["Sterilizing", "Sterilized", "Grinding"].includes(
                        apiRef.current.getCellParams(id, "cycle_state").value
                    )
                ) {
                    columnFields.forEach((field) => {
                        switch (field) {
                            case "time_started" || "time_ended":
                                const timeVal = apiRef.current.getCellParams(id, field).value;
                                const tz = deviceTimezone ? deviceTimezone : userTimezone;
                                row[field] = DateTime.fromISO(timeVal, { zone: tz })
                                    .toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, timeZoneName: "short" })
                                    .replace(",", "");
                                break;
                            default:
                                row[field] = apiRef.current.getCellParams(id, field).value;
                        }
                    });
                }
            } else {
                columnFields.forEach((field) => {
                    switch (field) {
                        case "time_started" || "time_ended":
                            const timeVal = apiRef.current.getCellParams(id, field).value;
                            const tz = deviceTimezone ? deviceTimezone : userTimezone;
                            row[field] = DateTime.fromISO(timeVal, { zone: tz })
                                .toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, timeZoneName: "short" })
                                .replace(",", "");
                            break;
                        default:
                            row[field] = apiRef.current.getCellParams(id, field).value;
                    }
                });
            }
            return row;
        });

        const pdf = cycleReport(
            deviceFacilityAddress,
            biochallengeInfo?.is_airport,
            !complianceReport,
            deviceSerial,
            currentPressureUnit,
            cycles
        );

        return pdf;
    };

    const PdfExportMenuItem = (props) => {
        const apiRef = useGridApiContext();

        const { hideMenu } = props;

        return (
            <MenuItem
                onClick={() => {
                    const pdf = getPdf(apiRef);
                    hideMenu?.();
                }}>
                Export PDF
            </MenuItem>
        );
    };

    const getUnfilteredRows = ({ apiRef }) => {
        const sortedRows = apiRef.current
            .getSortedRows()
            .filter((row) => !["Sterilizing", "Sterilized", "Grinding"].includes(row.cycle_state))
            .sort((a, b) => {
                const aTime = DateTime.fromISO(a.time_started);
                const bTime = DateTime.fromISO(b.time_started);
                return aTime > bTime ? 1 : -1;
            })
            .map((row) => row.cycle_id);
        return sortedRows;
    };

    const getCycleTypeDisplay = (value) => {
        if (value === "biovalidation") {
            return "Bio-Validation";
        } else if (value === "biochallenge") {
            return "Bio-Challenge";
        } else if (value === "prv_test") {
            return "PRV-Test";
        } else if (value === "inspectgrinder") {
            return "Grinder Inspection";
        } else {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    };

    const getWasteTypeDisplay = (value) => {
        let words = value.replace("_", " ").replace("_", " ").split(" ");
        for (let i = 0; i < words.length; i++) {
            if (words[i] === "prv") {
                words[i] = words[i].toUpperCase();
            } else if (words[i] === "bio") {
                words[i] = "Indicator";
            } else {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }
        }
        return words.join(" ");
    };

    const csvOptions = {
        allColumns: true,
        getRowsToExport: getUnfilteredRows,
        fileName: `${complianceReport ? "compliance" : "comprehensive"}_${deviceSerial}_${DateTime.now().toFormat(
            "LL-dd-yy_hh-mm-ss"
        )}`,
    };

    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <PdfExportMenuItem />
        </GridToolbarExportContainer>
    );

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );

    const columns = [
        {
            field: "serial_number",
            headerName: t("Device Serial"),
            headerClassName: "sterilis-app-theme--header",
            headerAlign: "center",
            align: "center",
            flex: 0.25,
        },
        {
            field: "time_started",
            headerName: t("Date"),
            type: "dateTime",
            headerClassName: "sterilis-app-theme--header",
            headerAlign: "center",
            align: "center",
            flex: 0.5,
            valueFormatter: (params) => {
                const formatted = DateTime.fromISO(params.value, {
                    zone: deviceTimezone ? deviceTimezone : userTimezone,
                })
                    .toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, timeZoneName: "short" })
                    .replace(",", "");
                return `${formatted}`;
            },
        },
        {
            field: "weight",
            headerName: t("Weight"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.25,
            valueGetter: (params) => {
                return getDisplayWeight(params.value, params.row.waste_type, { weight: weightUnit });
            },
        },
        {
            field: "cycle_type",
            headerName: t("Cycle Type"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.3,
            valueGetter: ({ value }) => getCycleTypeDisplay(value),
        },
        {
            field: "waste_type",
            headerName: t("Waste Type"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.3,
            valueGetter: ({ value }) => getWasteTypeDisplay(value),
        },
        {
            field: "cycle_time",
            headerName: t("Cycle Time"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.25,
            valueGetter: ({ row }) =>
                calculateCycleTime(
                    row.time_started,
                    row.time_ended,
                    row.latest_cycle_event_timestamp,
                    row.latest_cycle_status_timestamp
                ),
        },
        {
            field: "steam_time",
            headerName: t("Steam Time"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.25,
            valueGetter: ({ row }) => `${Math.round(row.steam_time)} ${prettyMins(Math.round(row.steam_time))}`,
        },
        {
            field: "cook_time",
            headerName: t("Cook Time"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.25,
            valueGetter: ({ row }) => `${Math.round(row.cook_time)} ${prettyMins(Math.round(row.cook_time))}`,
        },
        {
            field: "max_pres_A",
            headerName: t("Max PSI(a)"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.25,
            valueGetter: (params) => parseFloat(params.row.max_pvA).toFixed(2),
        },
        {
            field: "max_temp",
            headerName: t("Max Temp (C)"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.25,
            valueGetter: (params) => Math.round(params.row.max_tv),
        },
        {
            field: "times",
            headerName: t("Times"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "left",
            flex: 0.25,
            disableExport: true,
            valueGetter: ({ row }) => {
                return {
                    cook_time: Math.round(row.cook_time),
                    steam_time: Math.round(row.steam_time),
                    cycle_time: calculateCycleTime(
                        row.time_started,
                        row.time_ended,
                        row.latest_cycle_event_timestamp,
                        row.latest_cycle_status_timestamp
                    ),
                };
            },
            renderCell: (params) => (
                <Box>
                    <Typography fontSize={12}>Cycle: {params.value.cycle_time}</Typography>
                    <Typography fontSize={12}>
                        Steam: {Math.round(params.value.steam_time)}{" "}
                        {Math.round(params.value.steam_time) > 1 ? t("mins") : t("min")}
                    </Typography>
                    <Typography fontSize={12}>
                        Cook: {Math.round(params.value.cook_time)}{" "}
                        {Math.round(params.value.cook_time) > 1 ? t("mins") : t("min")}
                    </Typography>
                </Box>
            ),
        },
        {
            field: "user",
            headerName: t("Run By"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.4,
            valueGetter: ({ row }) => row.full_name,
        },
        {
            field: "cycle_state",
            headerName: t("Status"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.4,
        },
        {
            field: "cycle_comments",
            headerName: t("Comments"),
            headerAlign: "center",
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            flex: 0.4,
        },
    ];

    if (isGroupSterilisOrDist(group)) {
        // @ts-ignore
        columns.push({
            field: "actions",
            type: "actions",
            disableExport: true,
            headerClassName: "sterilis-app-theme--header",
            align: "center",
            getActions: (params) => {
                if (params.row.metadata) {
                    return [
                        <GridActionsCellItem
                            label="Metrics"
                            size="large"
                            onClick={handleMetricsClick(params.row)}
                            icon={<AnalyticsTwoToneIcon sx={{ fontSize: 30 }} color="secondary" />}
                        />,
                    ];
                } else {
                    return [];
                }
            },
        });
    }

    const cycleClick = (cycle) => {
        dispatch(setSelectedCycle(cycle.row));
        // @ts-ignore
        dispatch(fetchCycleEvents(cycle.id));
    };

    return (
        <Box
            sx={{
                width: "100%",
                "& .sterilis-app-theme--header": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.getContrastText(theme.palette.primary.main),
                    fontSize: 14,
                    fontWeight: "bold",
                },
                "& .sterilis-app-theme--row": {
                    fontSize: 12,
                },
            }}>
            <DataGrid
                // @ts-ignore
                columns={columns}
                density="comfortable"
                disableDensitySelector
                hideFooterSelectedRowCount
                loading={loading}
                rows={deviceCycles}
                pagination
                getRowId={(row) => row.cycle_id}
                autoHeight
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 50, 100]}
                components={{ Toolbar: CustomToolbar }}
                getRowClassName={() => `sterilis-app-theme--row`}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            serial_number: false,
                            cycle_comments: false,
                            cook_time: false,
                            steam_time: false,
                            cycle_time: false,
                            max_pres_A: false,
                            max_pres_G: false,
                            max_temp: false,
                        },
                    },
                }}
                componentsProps={{
                    toolbar: {
                        background: "primary",
                        printOptions: {
                            allColumns: true,
                            hideFooter: true,
                            hideToolbar: true,
                        },
                    },
                }}
                onRowClick={cycleClick}
                onSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length > 0) {
                        setSelectionModel(newSelectionModel);
                    }
                }}
                selectionModel={selectionModel}
            />
        </Box>
    );
};

export default CycleReportTable;
