/*
*  A modal for editing a facility's information
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {Button, Dropdown, Header, Modal, TransitionablePortal, Checkbox} from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import SimpleLoader from "../../../SimpleLoader";
import AuthService from "../../../AuthService";
import get from "lodash/get";

const Auth = new AuthService();

class EditFacilityModal extends React.Component {
  state = {
    facilityCountry: '',
    facilityName: '',
    facilityThoroughfare: '',
    facilitySubThoroughfare: '',
    facilityLocality: '',
    facilitySubLocality: '',
    facilityState: '',
    stateValidationError: false,
    stateName: 'STATE NAME TEMP',
    facilityIncubationPeriod: '',
    facilityElevation: 0,
    facilityPostalCode: '',
    stateCountryObj: {},
    regionTimezones: {},
    facilityRegionID: 0,
    allowModalToBeClosed: true,
  };

  componentDidMount() {
    const {
      facility
    } = this.props;

    this.setState({
      modalLoading: true,
    });

    this.getRegionSettings().then(() => {
      const stateArr = get(this.state.stateCountryObj, facility['region_setting']['abbreviation'], null);

      // the && checks if it's set, can't get nth index of something undefined
      const stateName = stateArr && stateArr[0]; // 0 is the region's name,

      this.setState({
        facilityCountry: facility['region_setting']['country'],
        facilityName: facility['facility_name'],
        facilityThoroughfare: facility['thoroughfare'],
        facilitySubThoroughfare: facility['sub_thoroughfare'],
        facilityLocality: facility['locality'],
        facilitySubLocality: facility['sub_locality'],
        facilityPostalCode: facility['postal_code'],
        facilityType: facility['facility_type'],
        facilityIncubationPeriod: facility['incubation_period'],
        facilityElevation: facility['elevation'],
        facilityState: facility['region_setting']['abbreviation'],
        facilityRegionID: facility['region_setting']['id'],
        facilityWasterPerMonth: facility['waste_per_month'],
        facilitySpecificCooktime: facility['facility_specific_cooktime'],
        enableFacilityCooktime: facility['enable_facility_specific_cooktime'],
        facilityBioValidationWasteType: facility['bio_validation_waste_type'],
        stateName,
        modalLoading: false,
      });

    });
  }

  submitFacilityChanges = (e) => {
    e.preventDefault();

    const {
      facilityName,
      facilityThoroughfare,
      facilitySubThoroughfare,
      facilityLocality,
      facilitySubLocality,
      facilityType,
      facilityIncubationPeriod,
      facilityElevation,
      facilityPostalCode,
      facilityRegionID,
      facilityWasterPerMonth,
    } = this.state;

    const {
      facility
    } = this.props;

    const facilityID = facility['id'];

    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });

    Auth.fetch(`/api/facility/${facilityID}`, {
      method: 'PATCH',
      body: JSON.stringify({
        'facility_name': facilityName,
        'facility_type': facilityType,
        'elevation': facilityElevation,
        'incubation_period': facilityIncubationPeriod,
        'waste_per_month': facilityWasterPerMonth,
        'thoroughfare': facilityThoroughfare,
        'sub_thoroughfare': facilitySubThoroughfare,
        'locality': facilityLocality,
        'sub_locality': facilitySubLocality,
        'postal_code': facilityPostalCode,
        'region_setting': facilityRegionID,
        'enable_facility_specific_cooktime': this.state.enableFacilityCooktime,
        'facility_specific_cooktime': this.state.enableFacilityCooktime ? this.state.facilitySpecificCooktime : null,
        'bio_validation_waste_type': this.state.facilityBioValidationWasteType,
      })
    }).then(data => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.preparePage();
        this.props.triggerEditFacilityModal(null);
        this.props.notifySuccess('edited', 'facility', data['facility_name'])
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.triggerEditFacilityModal(null);
        this.props.notifyFailure()
      });

    });


  };
  handleCountryChange = (event, data) => {
    this.setState({
      facilityCountry: data.value,
      facilityState: '',
      facilityWasterPerMonth: '',
      facilityRegionID: this.figureOutRegionIDBasedOnCountry(data.value),
    });
  };

  figureOutRegionIDBasedOnCountry = (facilityCountry) => {
    const {
      facilityRegionID,
      regionTimezones
    } = this.state;

    // If it's US or CA, they'll need to choose a state, and onChange name=facilityState logic handles setting RegionSetting
    if (facilityCountry === 'us' || facilityCountry === 'ca') {
      return null
    } else {
      // Select the first timezone out of the region timezones
      const regionsOnlyTimezones = Object.values(regionTimezones[facilityCountry])[0];
      const isCurrentRegionValueValid = regionsOnlyTimezones.some((timezone) => {
        return timezone.regionID === facilityRegionID
      });

      if (!isCurrentRegionValueValid) {
        return regionsOnlyTimezones[0].regionID
      } else {
        return facilityRegionID;
      }

    }

  };

  handleWasteChange = (event, data) => {
    this.setState({
      facilityWasterPerMonth: data.value
    });
  };

  handleIncubationChange = (event, data) => {
    this.setState({
      facilityIncubationPeriod: data.value
    });
  };

  handleTimezoneChange = (event, data) => {
    this.setState({
      facilityRegionID: data.value
    });
  };

  handleFacilityTypeChange = (event, data) => {
    if (data.value === 'airport' || data.value === 'cannabis') {
      this.setState({
        facilityBioValidationWasteType: 'indicator'
      });
    }

    this.setState({
      facilityType: data.value,
    });
  };

  handleFacilityBioValidationWasteTypeChange = (event, data) => {
    this.setState({
      facilityBioValidationWasteType: data.value
    });
  };


  handleEnableFacilityCooktime = () => {
    this.setState((prevState) => {
      return {enableFacilityCooktime: !prevState.enableFacilityCooktime};
    });
  };

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (name === 'facilityState') {
      const value = event.currentTarget.value.toUpperCase();

      const {
        facilityCountry,
        regionTimezones
      } = this.state;

      // Check if the country exists inside of regionTimezones, and that the state exists within the country
      if (regionTimezones[facilityCountry] && regionTimezones[facilityCountry][value]) {
        // These following two lines will give the user the appropriate region setting for the state they've entered
        const stateArr = this.state.stateCountryObj[value];
        const regionID = stateArr && stateArr[1];
        this.setState({
          facilityRegionID: regionID,
          stateValidationError: false,
          // If the user is switching to Texas, put the default facilityWastePerMonth value
          facilityWasterPerMonth: stateArr[0] === 'Texas' ? '51-100' : '',
          [name]: value,
        });

      } else {
        if (value) { // if there's a value, that means the user entered an invalid state
          this.setState({
            facilityRegionID: null,
            stateValidationError: true,
            facilityWasterPerMonth: '',
            [name]: value
          })
        } else {
          this.setState({
            facilityRegionID: null,
            stateValidationError: false,
            facilityWasterPerMonth: '',
            [name]: value
          })
        }
      }
    } else {
      this.setState({[name]: value});
    }

  };

  getRegionSettings = () => {
    return Auth.fetch(`/api/region-timezone/`)
      .then(data => {
        const stateCountryObj = {};

        const regionTimezones = data.reduce((prev, curr) => {
          // check if the country has been added yet
          if (prev[curr.timezone.country]) {
            // if it exists in country obj already
            if (prev[curr.timezone.country][curr.abbreviation]) {
              // push to the array
              prev[curr.timezone.country][curr.abbreviation].push({
                'timezone': curr.timezone.timezone,
                'display_label': curr.timezone.display_label,
                'regionID': curr.id
              })
            } else {
              // create an array of timezones
              prev[curr.timezone.country][curr.abbreviation] = [{
                'timezone': curr.timezone.timezone,
                'display_label': curr.timezone.display_label,
                'regionID': curr.id
              }];
            }
          } else {
            // Create the object
            prev[curr.timezone.country] = {
              // Key: timezoneArray
              [curr.abbreviation]: [{
                'timezone': curr.timezone.timezone,
                'display_label': curr.timezone.display_label,
                'regionID': curr.id
              }]
            };
          }
          return prev;
        }, {});

        data.forEach((item) => {
          if (item.timezone.country === 'us' || item.timezone.country === 'ca') {
            stateCountryObj[item.abbreviation] = [item.region_name, item.id];
          }
        });

        this.setState({
          regionTimezones: regionTimezones,
          stateCountryObj: stateCountryObj,
        });
      }).catch(err => {
        Sentry.captureException(err);
        this.setState({
          modalLoading: false,
        })
      });
  };

  findTimezoneDropdownOptions = () => {
    const {
      facilityCountry,
      facilityState,
      regionTimezones,
    } = this.state;
    if (regionTimezones[facilityCountry]) {
      if (regionTimezones[facilityCountry][facilityState]) {
        const statesTimezones = regionTimezones[facilityCountry][facilityState];
        return statesTimezones.map((timezone, idx) => {
          return {
            key: timezone.regionID,
            value: timezone.regionID,
            text: timezone.display_label
          }
        });
      } else {
        // Australia has states, but we aren't asking for them or filtering timezones based on the state values
        if (facilityCountry === 'au') {
          const regionStateNames = Object.keys(regionTimezones[facilityCountry]);
          return regionStateNames.map((state) => {
            const timezone = regionTimezones[facilityCountry][state][0];
            return {
              key: timezone.regionID,
              value: timezone.regionID,
              text: timezone.display_label
            }
          });
        } else if (facilityCountry === 'us' || facilityCountry === 'ca') {
          // we want US and CA to select a state before timezone, so we can filter and get them
          // the right timezone for the state BECAUSE timezone selection is _really_ region_setting selection
          return []
        } else {
          // NOTICE: this ONLY loops over the first value in the regionTimezones[facilityCountry] object because we
          // are not filtering timezones based on state entry
          const regionsOnlyTimezones = Object.values(regionTimezones[facilityCountry])[0];
          return regionsOnlyTimezones.map((timezone, idx) => {
            return {
              key: idx,
              value: timezone.regionID,
              text: timezone.display_label
            }
          });

        }
      }
    }
  };

  timezoneDropdown = () => {
    const {
      t
    } = this.props;

    const {
      facilityRegionID
    } = this.state;
    // Check if the country exists inside of regionTimezones
    const timezoneDropdownOptions = this.findTimezoneDropdownOptions();

    return timezoneDropdownOptions.length > 0 ? <div className="form-group">
      <label className="fake-float" htmlFor="timezoneDropdown">{t('Timezone')}</label>
      <Dropdown
        className='full-width forty-five-height'
        name='timezoneDropdown'
        selection
        id='timezoneDropdown'
        options={timezoneDropdownOptions}
        value={facilityRegionID}
        onChange={this.handleTimezoneChange}
        required
      />
    </div> : null
  };

  render() {
    const {
      t
    } = this.props;

    const {
      modalLoading,
      facilityCountry,
      facilityName,
      facilityThoroughfare,
      facilitySubThoroughfare,
      facilityLocality,
      facilitySubLocality,
      facilityState,
      stateValidationError,
      facilityWasterPerMonth,
      facilityPostalCode,
      facilityIncubationPeriod,
      facilityElevation,
      facilityRegionID
    } = this.state;

    const stateArr = get(this.state.stateCountryObj, facilityState, null);

    // the && checks if it's set, can't get nth index of something undefined
    const stateName = stateArr && stateArr[0]; // 0 is the region's name,

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };

    const countryDropdown = [
      {
        key: 0,
        value: 'us',
        id: 'us',
        text: t('United States')
      },
      {
        key: 1,
        value: 'ar',
        id: 'ar',
        text: t('Argentina')
      },
      {
        key: 2,
        value: 'ca',
        id: 'ca',
        text: t('Canada')
      },
      {
        key: 3,
        value: 'ec',
        id: 'ec',
        text: t('Ecuador')
      },
      {
        key: 4,
        value: 'in',
        id: 'in',
        text: t('India')
      },
      {
        key: 5,
        value: 'au',
        id: 'au',
        text: t('Australia')
      },
    ];

    const texasDropdown = [
      {
        key: 0,
        value: '<51',
        text: t('1-50 pounds - BC every 3 months')
      },
      {
        key: 1,
        value: '51-100',
        text: t('51-100 pounds - BC every 4 weeks')
      },
      {
        key: 2,
        value: '101-200',
        text: t('101-200 pounds - BC every 2 weeks')
      },
      {
        key: 3,
        value: '>200',
        text: t('> 200 pounds - BC every week')
      },
    ];

    const incubationDropdown = [
      {
        key: 0,
        value: 86400,
        text: t('24 hours')
      },
      {
        key: 1,
        value: 172800,
        text: t('48 hours')
      },
    ];

    const facilityTypeDropdown = [
      {
        key: 0,
        value: 'RMW',
        text: t('Regulated Medical Waste')
      },
      {
        key: 1,
        value: 'airport',
        text: t('Airport')
      },
      {
        key: 2,
        value: 'cannabis',
        text: t('Cannabis')
      },
    ];

    const facilityBioValidationWasteTypeDropdown = [
      {
        key: 0,
        value: 'indicator',
        text: t('Biological Challenge Indicator'),
      },
      {
        key: 1,
        value: 'sharps',
        text: t('Biological Validation with Sharps'),
      },
      {
        key: 2,
        value: 'redbags',
        text: t('Biological Validation with Red Bags')
      },
      {
        key: 3,
        value: 'dialyzers',
        text: t('Biological Validation with Dialyzers')
      },
    ];


    return (
      <TransitionablePortal
        open={this.props.editFacilityModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerEditFacilityModal(null)
          }}
        >
          <Header>
            <h2>{t('Edit Facility')}</h2>
          </Header>
          <Modal.Content>
            {modalLoading ? (
              <SimpleLoader/>
            ) : null}
            <form id='editFacilityForm'
                  onSubmit={this.submitFacilityChanges}>
              <div className="split-container">
                <div className="fifty-split minor-left-right-padding">
                  <div className="form-group">
                    <label className="fake-float" htmlFor="countryDropdown">{t('Country')}</label>
                    <Dropdown
                      className='full-width forty-five-height'
                      selection
                      id='countryDropdown'
                      options={countryDropdown}
                      value={facilityCountry}
                      onChange={this.handleCountryChange}
                    />
                  </div>
                  <div className="form-group">
                    <input value={facilityName}
                           name='facilityName'
                           onChange={this.handleChange}
                           type="text"
                           id="facilityNameInput"
                           className="form-control forty-five-height"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="facilityNameInput">{t('Facility Name')}</label>
                  </div>
                  <div className="form-group">
                    <input value={facilityThoroughfare}
                           name='facilityThoroughfare'
                           onChange={this.handleChange}
                           type="text"
                           id="facilityThoroughfareInput"
                           className="form-control forty-five-height"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="facilityThoroughfareInput">{t('Address')}</label>
                  </div>
                  <div className="form-group">
                    <input value={facilitySubThoroughfare}
                           name='facilitySubThoroughfare'
                           onChange={this.handleChange}
                           type="text"
                           id="facilitySubThoroughfareInput"
                           className="form-control forty-five-height"
                    />
                    <label className="form-control-placeholder" htmlFor="facilitySubThoroughfareInput">
                      {facilityCountry === 'ec' || facilityCountry === 'in' ?
                        t('Street, premise, and intersection') : t('Address line 2')}
                    </label>
                  </div>
                  <div className="form-group">
                    <input value={facilityLocality}
                           name='facilityLocality'
                           onChange={this.handleChange}
                           type="text"
                           id="facilityLocalityInput"
                           className="form-control forty-five-height"
                           required/>
                    <label className="form-control-placeholder" htmlFor="facilityLocalityInput">
                      {facilityCountry === 'us' ||
                      facilityCountry === 'ar' ||
                      facilityCountry === 'in'
                        ? t('City') :
                        facilityCountry === 'ca' ? t('Municipality') : t('Locality')
                      }
                    </label>
                  </div>
                  {facilityCountry === 'ec' || facilityCountry === 'in' ?
                    <div className="form-group">
                      <input value={facilitySubLocality}
                             name='facilitySubLocality'
                             onChange={this.handleChange}
                             type="text"
                             id="facilitySubLocalityInput"
                             className="form-control forty-five-height"
                             required/>
                      <label className="form-control-placeholder" htmlFor="facilitySubLocalityInput">
                        {t('Sub locality')}
                      </label>
                    </div>
                    : null
                  }
                  <div className="form-group">
                    <label className="fake-float" htmlFor="facilityType">{t('Regulatory Market')}</label>
                    <Dropdown
                      className='full-width forty-five-height'
                      selection
                      id='facilityType'
                      options={facilityTypeDropdown}
                      value={this.state.facilityType}
                      onChange={this.handleFacilityTypeChange}
                    />
                  </div>
                  {
                    this.state.facilityType === 'RMW' &&
                    <div className="form-group">
                      <label className="fake-float"
                             htmlFor="facilityType">{t('Biological Validation Waste Type')}</label>
                      <Dropdown
                        className='full-width forty-five-height'
                        selection
                        id='facilityBioValidationWasteType'
                        options={facilityBioValidationWasteTypeDropdown}
                        value={this.state.facilityBioValidationWasteType}
                        onChange={this.handleFacilityBioValidationWasteTypeChange}
                      />
                    </div>
                  }


                </div>

                <div className="fifty-split minor-left-right-padding">
                  {facilityCountry === 'us' || facilityCountry === 'ca' ?
                    <div className="form-group">
                      <input value={facilityState}
                             name='facilityState'
                             onChange={this.handleChange}
                             type="text"
                             id="facilityStateInput"
                             className="form-control forty-five-height"
                             required/>
                      <label className="form-control-placeholder" htmlFor="facilityStateInput">
                        {facilityCountry === 'us' ? t('State') : t('Province')}
                        {stateValidationError ?
                          <span className='validation-error'> - Invalid state</span>
                          : stateName ? ` - ${stateName}` : null
                        }
                      </label>
                    </div>
                    : null
                  }
                  {modalLoading === false && this.timezoneDropdown()}
                  {
                    stateName === 'Texas' ? (
                      <div className="form-group">
                        <label className="fake-float"
                               htmlFor="texasDropdown">{t('Waste load per month')}</label>
                        <Dropdown
                          className='full-width forty-five-height'
                          search
                          selection
                          id='texasDropdown'
                          required
                          options={texasDropdown}
                          value={facilityWasterPerMonth}
                          onChange={this.handleWasteChange}
                        />
                      </div>
                    ) : null
                  }

                  <div className="form-group">
                    <input value={facilityPostalCode}
                           name='facilityPostalCode'
                           onChange={this.handleChange}
                           type="text"
                           id="facilityPostalCodeInput"
                           className="form-control forty-five-height"
                           required/>
                    <label className="form-control-placeholder" htmlFor="facilityPostalCodeInput">
                      {facilityCountry === 'us' ? t('Zipcode') : t('Postal code')}
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="fake-float" htmlFor="texasDropdown">{t('Incubation period')}</label>
                    <Dropdown
                      className='full-width forty-five-height'
                      search
                      selection
                      id='texasDropdown'
                      options={incubationDropdown}
                      value={facilityIncubationPeriod}
                      onChange={this.handleIncubationChange}
                    />
                  </div>

                  <div className="form-group">
                    <input value={facilityElevation}
                           name='facilityElevation'
                           onChange={this.handleChange}
                           type="number"
                           id="facilityElevationInput"
                           className="form-control forty-five-height"
                           required/>
                    <label className="form-control-placeholder" htmlFor="facilityElevationInput">
                      {t('Elevation')}
                    </label>
                  </div>

                  <div className="form-group">
                    <Checkbox
                      label={t('Facility specific cooktime')}
                      name='enableFacilityCooktime'
                      id='enableFacilityCooktime'
                      checked={this.state.enableFacilityCooktime}
                      onChange={this.handleEnableFacilityCooktime}
                    />
                  </div>
                  {
                    this.state.enableFacilityCooktime ?
                      (
                        <div className="form-group">
                          <input value={this.state.facilitySpecificCooktime}
                                 name='facilitySpecificCooktime'
                                 onChange={this.handleChange}
                                 type="number"
                                 id="facilitySpecificCooktime"
                                 className="form-control forty-five-height"
                                 required/>
                          <label className="form-control-placeholder" htmlFor="facilitySpecificCooktime">
                            {t('Cook time (minutes)')}
                          </label>
                        </div>
                      ) : null

                  }
                </div>
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn'
                    value="Submit"
                    type="submit"
                    form='editFacilityForm'
                    id='editFacilityBtn'
                    disabled={stateValidationError || facilityRegionID === null}
            >{t('Submit changes')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(EditFacilityModal);
