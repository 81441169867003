import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton,
    InputAdornment,
    MenuItem,
    Skeleton,
    Stack,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { DeleteTwoTone, RefreshTwoTone } from "@mui/icons-material";
import SubCard from "src/components/material/Cards/SubCard";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchDefaultWasteConfig,
    resetUpdatedConfig,
    saveWasteTypeConfig,
    setSelectedWasteType,
    setUpdatedWasteConfig,
    setUpdatedConfig,
    patchDeviceConfig,
    resetWasteTypeConfig,
} from "../deviceInfoSlice";
import { StyledDialog, StyledTableCell, StyledTableRow } from ".";
import { capitalizeFirstLetter } from "src/components/library/helpers";

const EditWasteTypeModal = (props) => {
    const { t } = useTranslation("translations");
    const {
        selectedDeviceConfig,
        selectedWasteType,
        modifiedWasteConfig,
        regulatoryComplianceDropdown,
        regulatoryCompliance,
        defaultWasteConfig,
        inactiveWasteTypes,
        newWasteType,
        updatedConfig,
    } = useSelector((store) => store.deviceInfo);

    const loading = useSelector((store) => store.deviceInfo.loading.wasteTypeConfig);
    const pendingWasteUpdate = useSelector((store) => store.deviceInfo.loading.wasteUpdate);
    const [selectedState, setSelectedState] = useState(
        selectedDeviceConfig?.facility?.region_setting?.abbreviation +
            selectedDeviceConfig?.facility.region_setting.timezone.id
    );
    const [selectedStateCompliance, setSelectedStateCompliance] = useState(null);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [changes, setChanges] = useState([]);
    const [comments, setComments] = useState(null);

    const fields = [
        { field: "cook_temp", label: "Cook Temp", adornment: "°C", category: "steam" },
        { field: "cook_time", label: "Cook Time", adornment: "sec", category: "steam" },
        { field: "added_cook_time", label: "Added Cook Time", adornment: "sec", category: "steam" },
        { field: "goal_pumpdown", label: "Goal Pumpdown", adornment: "psi", category: "steam" },
        { field: "pv_unsafe", label: "Pv Unsafe", adornment: "psi", category: "steam" },
        { field: "drain_open_time", label: "Drain Open Time", adornment: "sec", category: "steam" },
        { field: "drain_timeout_time", label: "Drain Timeout", adornment: "sec", category: "steam" },
        { field: "drain_below_pressure", label: "Drain Below Pressure", adornment: "psi", category: "steam" },
        { field: "grind_count", label: "Grind Count", category: "grind" },
        { field: "grind_forward_time", label: "Grind Forward Time", adornment: "sec", category: "grind" },
        { field: "grind_reverse_time", label: "Grind Reverse Time", adornment: "sec", category: "grind" },
        { field: "grind_final_time", label: "Final Grind Time", adornment: "sec", category: "grind" },
        { field: "max_rev_stall_count", label: "Max Rev Stall Count", category: "grind" },
        { field: "weight_limit_imperial", label: "Weight Limit", adornment: "lbs" },
        { field: "weight_intervals", label: "Weight Interval", adornment: "lb(s)" },
        {
            field: "weight_limit_to_add_time",
            label: "Weight Limit To Add Time",
            adornment: "lbs",
            category: "steam",
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        selectedDeviceConfig &&
            selectedWasteType &&
            dispatch(
                // @ts-ignore
                fetchDefaultWasteConfig({
                    wasteType: selectedWasteType,
                    facilityId: selectedDeviceConfig?.facility?.id,
                    getSystemDefaults: false,
                })
            );
    }, [selectedWasteType]);

    const handleFieldReset = (field) =>
        dispatch(setUpdatedWasteConfig({ field: field, value: defaultWasteConfig[field] }));

    const handleCancel = (event) => {
        props.setEditWasteDialogOpen(false);
        dispatch(setSelectedWasteType(null));
        dispatch(resetUpdatedConfig());
    };

    const handleSubmit = (event) => {
        const wasteChanges = Object.keys(defaultWasteConfig)
            .filter((config) => {
                if (
                    (!modifiedWasteConfig.use_sterilize &&
                        fields.find((item) => item.field === config)?.category === "steam") ||
                    (!modifiedWasteConfig.use_grind &&
                        fields.find((item) => item.field === config)?.category === "grind")
                ) {
                    return false;
                } else {
                    return true;
                }
            })
            .map((config) => {
                if (defaultWasteConfig[config] !== modifiedWasteConfig[config]) {
                    return {
                        config: selectedWasteType + "-" + config,
                        old_value: defaultWasteConfig[config],
                        new_value: modifiedWasteConfig[config],
                    };
                }
            })
            .filter((item) => item);

        if (newWasteType && selectedWasteType) {
            dispatch(setUpdatedConfig({ field: selectedWasteType, value: true }));
            wasteChanges.push({
                config: inactiveWasteTypes.find((item) => item.key === selectedWasteType).text,
                old_value: "False",
                new_value: "True",
            });
        }

        setChanges(wasteChanges);
        setSaveDialogOpen(true);
    };

    const submitChanges = () => {
        if (newWasteType && updatedConfig) {
            dispatch(
                // @ts-ignore
                patchDeviceConfig({
                    configId: selectedDeviceConfig.id,
                    body: { ...updatedConfig, change_desc: comments },
                })
            ).then((resp) => {
                props.setSaveStatus(resp.meta.requestStatus);
                dispatch(resetUpdatedConfig());
            });
        }

        if (changes.length > 0 && selectedDeviceConfig && modifiedWasteConfig) {
            dispatch(
                // @ts-ignore
                saveWasteTypeConfig({
                    ...modifiedWasteConfig,
                    device_config_id: selectedDeviceConfig.id,
                    waste_type_changes: changes,
                    change_desc: comments,
                })
            ).then((resp) => {
                props.setWasteSaveStatus(resp.meta.requestStatus);
                dispatch(resetWasteTypeConfig());
                setSaveDialogOpen(false);
                props.setEditWasteDialogOpen(false);
                props.setEditMode(false);
            });
        }
    };

    const displayItemValue = (item) => {
        if (typeof item === "boolean") {
            return capitalizeFirstLetter(item.toString());
        } else {
            return item;
        }
    };

    const resetToSystemDefaults = () => {
        dispatch(
            // @ts-ignore
            fetchDefaultWasteConfig({
                wasteType: selectedWasteType,
                facilityId: selectedDeviceConfig?.facility?.id,
                getSystemDefaults: true,
            })
        );
    };

    useEffect(() => {
        if (selectedState && regulatoryCompliance) {
            const complianceInfo = regulatoryCompliance.find(
                (item) => item.abbreviation + item.timezone_id === selectedState
            );
            if (complianceInfo) {
                setSelectedStateCompliance(complianceInfo);
            }
        }
    }, [selectedState]);

    const WasteField = (field, label, adornment) => (
        <Stack direction={"row"} spacing={2}>
            <TextField
                fullWidth
                label={t(label)}
                InputProps={{
                    endAdornment: adornment && <InputAdornment position="end">{adornment}</InputAdornment>,
                }}
                defaultValue={modifiedWasteConfig[field]}
                key={modifiedWasteConfig[field]}
                onBlur={(event) =>
                    dispatch(
                        setUpdatedWasteConfig({
                            field: field,
                            value: parseFloat(event.target.value) || parseInt(event.target.value),
                        })
                    )
                }
            />
            <Tooltip title={"Reset field to the previously saved value."}>
                <IconButton sx={{ padding: 2 }} onClick={() => handleFieldReset(field)}>
                    <RefreshTwoTone />
                </IconButton>
            </Tooltip>
        </Stack>
    );

    return (
        <>
            <Dialog open={props.open} maxWidth={"md"} fullWidth PaperProps={{ sx: { borderRadius: 4, padding: 2 } }}>
                <DialogTitle>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar sx={{ marginRight: 2 }}>
                            <DeleteTwoTone />
                        </Avatar>
                        Add / Edit Waste Type
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <Stack spacing={2}>
                            <SubCard title={t("Waste Type")}>
                                <Stack spacing={2}>
                                    {newWasteType ? (
                                        <TextField
                                            select
                                            label={t("Waste Type")}
                                            value={selectedWasteType}
                                            onChange={(event) => dispatch(setSelectedWasteType(event.target.value))}>
                                            {inactiveWasteTypes.map((type) => (
                                                <MenuItem key={type.key} value={type.value}>
                                                    {type.text}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    ) : (
                                        <TextField
                                            label={t("Waste Type")}
                                            disabled={true}
                                            defaultValue={modifiedWasteConfig.name}
                                        />
                                    )}
                                    <TextField label={t("Customized Name")} defaultValue={modifiedWasteConfig.name} />
                                </Stack>
                            </SubCard>
                            <SubCard title={t("Policy & Regulations")}>
                                <Stack spacing={2}>
                                    <TextField
                                        label={t("State Policy")}
                                        select
                                        fullWidth
                                        defaultValue={
                                            selectedDeviceConfig?.facility?.region_setting?.abbreviation +
                                            selectedDeviceConfig?.facility.region_setting.timezone.id
                                        }
                                        value={selectedState}
                                        onBlur={(event) => setSelectedState(event.target.value)}>
                                        {regulatoryComplianceDropdown.map((market) => (
                                            <MenuItem key={market.key} value={market.value}>
                                                {market.text}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Typography fontWeight={"bold"}>
                                        {t("Minimum") + ":"}{" "}
                                        <Typography pl={2} display={"inline"}>
                                            {selectedStateCompliance && selectedStateCompliance.min_cook_temp} °C for{" "}
                                            {selectedStateCompliance && selectedStateCompliance.min_cook_time} minutes
                                        </Typography>
                                    </Typography>
                                    <Typography fontWeight={"bold"}>
                                        {t("Initial Bio Kill Validation Test") + ":"}
                                        <Typography pl={2} display={"inline"}>
                                            {selectedStateCompliance &&
                                                selectedStateCompliance.initial_validation_test_population}
                                        </Typography>
                                    </Typography>
                                </Stack>
                            </SubCard>
                            <SubCard title={t("Waste Type Settings")}>
                                <Grid container spacing={2}>
                                    <Grid xs={12}>
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={modifiedWasteConfig.use_sterilize || false}
                                                        onChange={(event) =>
                                                            dispatch(
                                                                setUpdatedWasteConfig({
                                                                    field: "use_sterilize",
                                                                    value: event.target.checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                                label={t("Sterilize")}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={modifiedWasteConfig.use_grind || false}
                                                        onChange={(event) =>
                                                            dispatch(
                                                                setUpdatedWasteConfig({
                                                                    field: "use_grind",
                                                                    value: event.target.checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                                label={t("Grind")}
                                            />
                                            <FormControlLabel
                                                label={t("Use Jack")}
                                                control={
                                                    <Switch
                                                        checked={modifiedWasteConfig.use_jack || false}
                                                        onChange={(event) =>
                                                            dispatch(
                                                                setUpdatedWasteConfig({
                                                                    field: "use_jack",
                                                                    value: event.target.checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label={t("Makes Clean Steam")}
                                                control={
                                                    <Switch
                                                        checked={modifiedWasteConfig.makes_clean_steam}
                                                        onChange={(event) =>
                                                            dispatch(
                                                                setUpdatedWasteConfig({
                                                                    field: "makes_clean_steam",
                                                                    value: event.target.checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                            />
                                        </Stack>
                                    </Grid>
                                    {fields.map((field) => {
                                        if (field.category) {
                                            if (modifiedWasteConfig.use_sterilize && field.category === "steam") {
                                                return (
                                                    <Grid xs={4}>
                                                        {WasteField(field.field, field.label, field?.adornment)}
                                                    </Grid>
                                                );
                                            }
                                            if (modifiedWasteConfig.use_grind && field.category === "grind") {
                                                return (
                                                    <Grid xs={4}>
                                                        {WasteField(field.field, field.label, field?.adornment)}
                                                    </Grid>
                                                );
                                            }
                                            return null;
                                        }
                                        return (
                                            <Grid xs={4}>{WasteField(field.field, field.label, field?.adornment)}</Grid>
                                        );
                                    })}
                                    <Grid
                                        xs={8}
                                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                resetToSystemDefaults();
                                            }}>
                                            Reset To Defaults
                                        </Button>
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Stack>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button color="success" variant="outlined" onClick={handleSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <StyledDialog
                open={saveDialogOpen}
                maxWidth={"lg"}
                fullWidth
                PaperProps={{ sx: { borderRadius: 4, padding: 2 } }}>
                <DialogTitle>Changes</DialogTitle>
                <DialogContent>
                    {pendingWasteUpdate ? (
                        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"} spacing={1}>
                            <CircularProgress color="primary" />
                            <Typography variant="caption" color={"primary"}>
                                {t("Saving Changes")}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack spacing={2}>
                            <TableContainer sx={{ minWidth: 600, borderRadius: 4 }}>
                                {changes && (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">
                                                    {t("Configuration Item")}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{t("Old Value")}</StyledTableCell>
                                                <StyledTableCell align="center">{t("New Value")}</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {changes.map((item, idx) => {
                                                return (
                                                    <StyledTableRow key={idx} hover>
                                                        <StyledTableCell
                                                            sortDirection="asc"
                                                            component="th"
                                                            scope="row"
                                                            align="center">
                                                            {item.config}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {displayItemValue(item.old_value)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {displayItemValue(item.new_value)}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                )}
                            </TableContainer>
                            <TextField
                                multiline
                                label="Comments"
                                value={comments}
                                onChange={(event) => setComments(event.target.value)}
                                sx={{ width: "50%" }}
                            />
                        </Stack>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setSaveDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="success" variant="outlined" onClick={() => submitChanges()}>
                        Submit
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    );
};

EditWasteTypeModal.propTypes = {};

export default EditWasteTypeModal;
