import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import SdCardTwoToneIcon from "@mui/icons-material/SdCardTwoTone";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import { Divider, List, ListSubheader } from "@mui/material";
import DeveloperBoardTwoToneIcon from "@mui/icons-material/DeveloperBoardTwoTone";
import ExploreTwoToneIcon from "@mui/icons-material/ExploreTwoTone";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import FileOpenTwoToneIcon from "@mui/icons-material/FileOpenTwoTone";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import FactoryTwoToneIcon from "@mui/icons-material/FactoryTwoTone";
import FileUploadTwoToneIcon from "@mui/icons-material/FileUploadTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import AddModeratorTwoToneIcon from "@mui/icons-material/AddModeratorTwoTone";
import TerminalTwoToneIcon from "@mui/icons-material/TerminalTwoTone";
import DocumentScannerTwoToneIcon from "@mui/icons-material/DocumentScannerTwoTone";
import PieChartTwoToneIcon from "@mui/icons-material/PieChartTwoTone";
import MonitorHeartTwoToneIcon from "@mui/icons-material/MonitorHeartTwoTone";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useTranslation } from "react-i18next";

import { AddBusinessTwoTone, PersonAddTwoTone, VisibilityTwoTone, AnalyticsTwoTone } from "@mui/icons-material";
import React, { useEffect } from "react";
import MenuItem from "./MenuItem";
import { useLocation } from "react-router-dom";

const DrawerItems = (props) => {
    const location = useLocation();

    const [sdOpen, setSdOpen] = React.useState(false);
    const [swOpen, setSwOpen] = React.useState(false);
    const [deviceOpen, setDeviceOpen] = React.useState(false);
    const [custOpen, setCustOpen] = React.useState(false);
    const [facOpen, setFacOpen] = React.useState(false);
    const [userOpen, setUserOpen] = React.useState(false);
    const [adminOpen, setAdminOpen] = React.useState(false);
    const [distOpen, setDistOpen] = React.useState(false);
    const [userCreateOpen, setUserCreateOpen] = React.useState(false);
    const {
        group,
        handleAllErrorsModal,
        handleCustomerDeviceModal,
        handleDeviceConfigsModal,
        handleDeviceSpreadsheetModal,
    } = props;
    const { t } = useTranslation("translations");
    const [reportOpen, setReportOpen] = React.useState(false);

    const superUserRoles = ["SterilisSuperUsers"];
    const sterilisUserRoles = ["SterilisSuperUsers", "SterilisPortalUsers", "SterilisWasteTypeAdmin"];

    const adminRoles = sterilisUserRoles.concat([
        "FSEs",
        "ExternalFSEs",
        "DistributorAdmins",
        "DistributorReadOnly",
        "DistributorFSEs",
    ]);

    const factoryRoles = sterilisUserRoles.concat(["FactoryWorkers", "DistributorAdmins"]);
    const distributorRoles = sterilisUserRoles.concat(["DistributorAdmins"]);

    const handleSdClick = () => {
        closeMenuItems();
        setSdOpen(!sdOpen);
    };
    const handleSwClick = () => {
        closeMenuItems();
        setSwOpen(!swOpen);
    };
    const handleDeviceClick = () => {
        closeMenuItems();
        setDeviceOpen(!deviceOpen);
    };
    const handleDistClick = () => {
        closeMenuItems();
        setDistOpen(!distOpen);
    };
    const handleCustClick = () => {
        closeMenuItems();
        setCustOpen(!custOpen);
    };
    const handleFacClick = () => {
        closeMenuItems();
        setFacOpen(!facOpen);
    };
    const handleUserClick = () => {
        closeMenuItems();
        setUserOpen(!userOpen);
    };
    const handleAdminClick = () => {
        closeMenuItems();
        setAdminOpen(!adminOpen);
    };
    const handleReportClick = () => {
        closeMenuItems();
        setReportOpen(!reportOpen);
    };
    const handleUserCreateClick = () => {
        setUserCreateOpen(!userCreateOpen);
    };

    const closeMenuItems = () => {
        setSdOpen(false);
        setSwOpen(false);
        setDeviceOpen(false);
        setDistOpen(false);
        setCustOpen(false);
        setFacOpen(false);
        setUserOpen(false);
        setAdminOpen(false);
        setReportOpen(false);
        setUserCreateOpen(false);
    };

    const menuOptions = [
        {
            text: "Dashboard",
            id: "dashboard",
            icon: <AnalyticsTwoTone />,
            openState: null,
            path: "/",
        },
        {
            text: "Devices",
            id: "devices",
            icon: <DeveloperBoardTwoToneIcon />,
            openState: deviceOpen,
            clickHandler: handleDeviceClick,
            options: [
                {
                    text: "View",
                    id: "view-devices",
                    tooltip: "View Devices",
                    icon: <VisibilityTwoTone />,
                    path: "/view/devices",
                },
                {
                    text: "Device Info",
                    tooltip: "Find A Machine",
                    id: "find-a-machine",
                    icon: <InfoTwoToneIcon />,
                    path: "/find-a-machine",
                },
                {
                    text: "Create",
                    id: "create-device",
                    tooltip: "Create Device",
                    icon: <AddCircleTwoToneIcon />,
                    requiredRoles: factoryRoles,
                    path: "/create/device",
                },
                {
                    text: "Assign To Facility",
                    id: "assign-devices",
                    tooltip: "Assign Devices",
                    icon: <SendTwoToneIcon />,
                    requiredRoles: factoryRoles,
                    path: "/assign/device-to-facility",
                },
            ],
        },
        {
            text: "Distributors",
            id: "distributors",
            icon: <FactoryTwoToneIcon />,
            openState: distOpen,
            requiredRoles: sterilisUserRoles,
            clickHandler: handleDistClick,
            options: [
                {
                    text: "View",
                    id: "view-distributors",
                    tooltip: "View Distributors",
                    icon: <VisibilityTwoTone />,
                    path: "/view/distributors",
                },
                {
                    text: "Create",
                    id: "create-distributor",
                    tooltip: "Create Distributor",
                    icon: <AddBusinessTwoTone />,
                    path: "/create/distributor",
                    requiredRoles: sterilisUserRoles,
                },
            ],
        },
        {
            text: "Customers",
            id: "customers",
            icon: <BusinessTwoToneIcon />,
            openState: custOpen,
            clickHandler: handleCustClick,
            options: [
                {
                    text: "View",
                    id: "view-customers",
                    tooltip: "View Customers",
                    icon: <VisibilityTwoTone />,
                    path: "/view/customers",
                },
                {
                    text: "Create",
                    id: "create-customers",
                    tooltip: "Create Customer",
                    icon: <AddBusinessTwoTone />,
                    path: "/create/v2/customer",
                    requiredRoles: distributorRoles,
                },
            ],
        },
        {
            text: "Facilities",
            id: "facilities",
            icon: <LocationOnTwoToneIcon />,
            openState: facOpen,
            clickHandler: handleFacClick,
            options: [
                {
                    text: "View",
                    id: "view-facilities",
                    tooltip: "View Facilities",
                    icon: <VisibilityTwoTone />,
                    path: "/view/customers",
                },
                {
                    text: "Create",
                    id: "create-facilities",
                    tooltip: "Create Facility",
                    icon: <AddLocationAltTwoToneIcon />,
                    path: "/create/v2/facility",
                    requiredRoles: distributorRoles,
                },
            ],
        },
        {
            text: "Users",
            id: "users",
            icon: <PeopleAltTwoToneIcon />,
            openState: userOpen,
            clickHandler: handleUserClick,
            options: [
                {
                    text: "View",
                    id: "view-users",
                    tooltip: "View Users",
                    icon: <VisibilityTwoTone />,
                    path: "/view/users",
                },
                {
                    text: "Create",
                    id: "create-users",
                    tooltip: "Create Users",
                    icon: <GroupAddIcon />,
                    openState: userCreateOpen,
                    clickHandler: handleUserCreateClick,
                    options: [
                        {
                            text: "Device Operator",
                            id: "create-operator",
                            tooltip: "Create Device Operator",
                            icon: <PersonAddTwoTone />,
                            path: "/create/v2/user?type=operator",
                        },
                        {
                            text: "Distributor User",
                            id: "create-dist-user",
                            tooltip: "Create Distributor User",
                            icon: <PersonAddTwoTone />,
                            path: "/create/v2/user?type=dist",
                            requiredRoles: distributorRoles,
                        },
                        {
                            text: "Site Admin",
                            id: "create-site-admin",
                            tooltip: "Create Site Admin",
                            icon: <AddModeratorTwoToneIcon />,
                            path: "/create/v2/user?type=cust",
                            requiredRoles: distributorRoles,
                        },
                        {
                            text: "Facility Admin",
                            id: "create-facility-admin",
                            tooltip: "Create Facility Admin",
                            icon: <AddModeratorTwoToneIcon />,
                            path: "/create/v2/user?type=fadm",
                            requiredRoles: distributorRoles,
                        },
                        {
                            text: "Sterilis User",
                            id: "create-user",
                            tooltip: "Create Sterilis User",
                            icon: <AddModeratorTwoToneIcon />,
                            path: "/create/v2/user?type=ster",
                            requiredRoles: sterilisUserRoles,
                        },
                    ],
                },
            ],
        },
    ];

    const deviceMenuOptions = [
        {
            text: "Admin",
            id: "admin",
            icon: <AdminPanelSettingsTwoToneIcon />,
            openState: adminOpen,
            requiredRoles: adminRoles,
            clickHandler: handleAdminClick,
            options: [
                {
                    text: "Customer Portal",
                    id: "customer-portal",
                    tooltip: "View Customer Portal",
                    icon: <DashboardTwoToneIcon />,
                    path: "/sa",
                },
                {
                    text: "Region Settings",
                    id: "region-settings",
                    tooltip: "View Region Settings",
                    icon: <ExploreTwoToneIcon />,
                    path: "/view/regions",
                },
                {
                    text: "Device Health",
                    id: "device-health",
                    tooltip: "Device Health Dashboard",
                    icon: <MonitorHeartTwoToneIcon />,
                    path: "/view/devicehealth",
                },
                {
                    text: "Ethel Log Parser",
                    id: "log-parser",
                    tooltip: "Log Parser (Legacy)",
                    icon: <DocumentScannerTwoToneIcon />,
                    path: "/log-parser",
                },
                {
                    text: "Cycle Metrics",
                    id: "cycle-metrics",
                    tooltip: "Cycle Metrics Dashboard",
                    icon: <PieChartTwoToneIcon />,
                    path: "/cycle-metrics",
                },
            ],
        },
        {
            text: "Reports",
            id: "reports",
            icon: <FileOpenTwoToneIcon />,
            openState: reportOpen,
            requiredRoles: adminRoles,
            clickHandler: handleReportClick,
            options: [
                {
                    text: "All Errors",
                    id: "all-errors-report",
                    tooltip: "All Errors Report",
                    icon: <ArticleTwoToneIcon />,
                    requiredRoles: adminRoles,
                    clickHandler: handleAllErrorsModal,
                },
                {
                    text: "Customer Device Report",
                    id: "cust-device-report",
                    tooltip: "Customer Device Report",
                    icon: <ArticleTwoToneIcon />,
                    requiredRoles: sterilisUserRoles,
                    clickHandler: handleCustomerDeviceModal,
                },
                {
                    text: "Device Configs",
                    id: "device-configs-report",
                    tooltip: "Device Configs Report",
                    icon: <ArticleTwoToneIcon />,
                    requiredRoles: distributorRoles,
                    clickHandler: handleDeviceConfigsModal,
                },
                {
                    text: "Specific Config Item(s)",
                    id: "config-items-report",
                    tooltip: "Device By Config Item(s) Report",
                    icon: <ArticleTwoToneIcon />,
                    requiredRoles: distributorRoles,
                    path: "/export/by-config",
                },
                {
                    text: "Specific Error Code(s)",
                    id: "specific-error-report",
                    tooltip: "Device By Error Code(s) Report",
                    icon: <ArticleTwoToneIcon />,
                    requiredRoles: distributorRoles,
                    path: "/export/specific-errors",
                },
                {
                    text: "Service Summary",
                    id: "service-summary-report",
                    tooltip: "Service Summary Report",
                    icon: <ArticleTwoToneIcon />,
                    requiredRoles: distributorRoles,
                    path: "/export/service-summary",
                },
                {
                    text: "Device Spreadsheet",
                    id: "device-spreadsheet-report",
                    tooltip: "Device Spreadsheet Report",
                    icon: <ArticleTwoToneIcon />,
                    requiredRoles: sterilisUserRoles,
                    clickHandler: handleDeviceSpreadsheetModal,
                },
            ],
        },
        {
            text: "SD Images",
            id: "sd-images",
            icon: <SdCardTwoToneIcon />,
            openState: sdOpen,
            requiredRoles: adminRoles,
            clickHandler: handleSdClick,
            options: [
                {
                    text: "View",
                    id: "view-sd-images",
                    tooltip: "View SD Images",
                    icon: <VisibilityTwoTone />,
                    path: "/view/sd-image",
                },
                {
                    text: "Upload",
                    id: "upload-sd-images",
                    tooltip: "Upload SD Images",
                    icon: <FileUploadTwoToneIcon />,
                    requiredRoles: superUserRoles,
                    path: "/upload/sd-image",
                },
            ],
        },
        {
            text: "Software",
            id: "software",
            icon: <TerminalTwoToneIcon />,
            openState: swOpen,
            requiredRoles: adminRoles,
            clickHandler: handleSwClick,
            options: [
                {
                    text: "View",
                    id: "view-software",
                    tooltip: "View Device Software",
                    icon: <VisibilityTwoTone />,
                    path: "/view/software",
                },
                {
                    text: "Upload",
                    id: "upload-software",
                    tooltip: "Upload Device Software",
                    icon: <FileUploadTwoToneIcon />,
                    path: "/upload/software",
                    requiredRoles: superUserRoles,
                },
                {
                    text: "Assign To Device",
                    id: "assign-software",
                    tooltip: "Assign Software To Device(s)",
                    icon: <SendTwoToneIcon />,
                    path: "/assign/software-to-devices",
                    requiredRoles: factoryRoles,
                },
            ],
        },
    ];

    const listItems = menuOptions.map((item) => {
        // subOption is added to handle tri-level options e.g. Users > Create > Sterilis User
        return <MenuItem item={item} key={`menu-${item.id}`} userGroup={group} subOption={false} />;
    });

    const managementItems = deviceMenuOptions.map((item) => {
        return <MenuItem item={item} key={`menu-${item.id}`} userGroup={group} />;
    });

    return (
        <React.Fragment>
            <List>{listItems}</List>
            <Divider />
            <List>
                <ListSubheader key={"device-management"} inset>
                    {t("Device Management")}
                </ListSubheader>
                {managementItems}
            </List>
        </React.Fragment>
    );
};

export default DrawerItems;
